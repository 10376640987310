// routes.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { ThemeProvider } from "@mui/material";

// Import the components for each route
import Homepage from './pages/Homepage';

import theme from "./theme";
import Navbar from "./components/Navbar";
import SupportLinks from "./components/SupportLinks";
import HealthCarousel from "./components/HealthCarousel";
import LoginDialog from "./components/LoginDialog";

import AdminProfile from "./components/AdminProfile";
import UserProfile from "./components/UserProfile";
import ServiceProvider from "./components/ServiceProvider";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Community from "./pages/Community";
import AboutUs from "./pages/AboutUs";
import MentalHealth from "./pages/MentalHealth";
import Partner from "./pages/Partner";
import EmergencySupport from "./pages/EmergencySupport";
import ContactUs from "./components/ContactUs";
import AdminLogin from "./pages/AdminLogin";

import Corporates from "./pages/Corporates";
import SetPassword from "./components/SetPassword";
import SetSuperuserPassword from "./components/SetSuperuserPassword";
import SetSPPassword from './components/SetSPPassword';
import SetcorporatePassword from './components/SetCorporatePassword';
import ViewBilling from "./components/ViewBilling";
import ViewQuestionaire from "./components/ViewQuestionaire";
import ViewUserAppointments from "./components/ViewUserAppointments";
import ViewUserAssessment from "./components/ViewUserAssessment";
import ViewUserSessions from "./components/ViewUserSessions";
import ViewUserTreatments from "./components/ViewUserTreatments";
import ViewUserPostRecovery from "./components/ViewUserPostRecovery";
import ViewUserSubscriptions from "./components/ViewUserSubscriptions";
import ViewUserSettings from './components/ViewUserSettings';
import CorporateProfile from "./components/CorporateProfile";
import ViewCorporateBilling from "./components/ViewCorporateBilling";
import ViewCorporateQuestionaire from "./components/ViewCorporateQuestionaire";
import ViewCorporatePostRecovery from "./components/ViewCorporatePostRecovery";
import ViewCorporateSubscriptions from "./components/ViewCorporateSubscriptions";
import ViewCorporateProfile from "./components/ViewCorporateProfile";
import ViewCorporateStaff from "./components/ViewCorporateStaff";

import ServiceProviderProfile from "./components/ServiceProviderProfile";
import ViewServiceProviderClients from "./components/ViewServiceProviderClients";
import ViewServiceProviderAppointments from "./components/ViewServiceProviderAppointments";
import ViewServiceProviderSessions from "./components/ViewServiceProviderSessions";
import ViewServiceProviderAssessments from "./components/ViewServiceProviderAssessments";
import ViewServiceProviderTreatment from "./components/ViewServiceProviderTreatment";
import ViewServiceProviderPostRecovery from "./components/ViewServiceProviderPostRecovery";
import ViewServiceProviderStaff from "./components/ViewServiceProviderStaff";
import ViewServiceProviderQuestionnaire from "./components/ViewServiceProviderQuestionnaire";
import ViewServiceProviderBills from "./components/ViewServiceProviderBills";
import ViewServiceProviderSettings from "./components/ViewServiceProviderSettings";
import ViewServiceProviderSubscriptions from "./components/ViewServiceProviderSubscriptions";
import ViewSuperuserAppointments from "./components/ViewSuperuserAppointments";
import ViewSuperuserSessions from "./components/ViewSuperuserSessions";
import ViewSuperuserTherapyTreatment from "./components/ViewSuperuserTherapyTreatment";
import ViewSuperuserPostRecovery from "./components/ViewSuperuserPostRecovery";
import ViewSuperuserTherapyAssessments from "./components/ViewSuperuserTherapyAssessments";
import ViewSuperuserUserAccounts from "./components/ViewSuperuserUserAccounts";
import ViewSuperuserTickets from "./components/ViewSuperuserTickets";
import ViewSuperuserQuestionnaires from "./components/ViewSuperuserQuestionnaires";
import ViewSuperuserSettings from "./components/ViewSuperuserSettings";
import ViewSuperuserResourceCenters from "./components/ViewSuperuserResourceCenters";
import ViewSuperuserBlogPosts from "./components/ViewSuperuserBlogPosts";
import ViewSuperuserCalendars from "./components/ViewSuperuserCalendars";
import ViewSuperuserSubscriptions from "./components/ViewSuperuserSubscriptions";
import ViewSuperuserBilling from "./components/ViewSuperuserBilling";
import ViewSuperuserClients from "./components/ViewSuperuserClients";
import ViewSuperuserMap from "./components/ViewSuperuserMap";
import ViewSuperuserShopItems from "./components/ViewSuperuserShopItems";
import ViewServiceProviderTickets from './components/ViewServiceProviderTickets';
import ViewServiceProviderCalendar from './components/ViewServiceProviderCalendar';
import ViewUserBilling from './components/ViewUserBilling';
import PatientQuestionnaire from './components/PatientQuestionnaire';
import PaymentConfirmation from './components/PaymentConfirmation';
import ProtectedRoute from './ProtectedRoute'; 

import SetStaffPassword from './components/SetStaffPassword';


const SelfAssessmentRoute = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const providerId = searchParams.get('provider_id');
  const appointmentId = searchParams.get('appointment_id')
  const name = searchParams.get('name');
  const email = searchParams.get('email');
  const phone = searchParams.get('phone');

  return (
    <PatientQuestionnaire
      provider_id={providerId}
      appointment_id={appointmentId}
      user={{ name, email, phone }} // Pass user data to the component
    />
  );
};


const AppRoutes = () => {


  return (
    
    <Router>
        
      {/* Include the Navbar here if it's common to all pages */}
      <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/user-profile" element={ <ProtectedRoute> <UserProfile /> </ProtectedRoute>} />
          <Route path="/view-appointments" element={<ProtectedRoute><ViewUserAppointments /></ProtectedRoute>} />
          <Route path="/view-assessments" element={<ProtectedRoute><ViewUserAssessment /></ProtectedRoute>} />
          <Route path="/view-sessions" element={<ProtectedRoute><ViewUserSessions /></ProtectedRoute>} />
          <Route path="/view-user-settings" element={<ProtectedRoute><ViewUserSettings /></ProtectedRoute>} />
          <Route path="/view-post-recovery" element={<ProtectedRoute><ViewUserPostRecovery /></ProtectedRoute>} />
          <Route path="/view-billing" element={<ProtectedRoute><ViewUserBilling /></ProtectedRoute>} />
          <Route path="/corporate-profile" element={<ProtectedRoute><CorporateProfile /></ProtectedRoute>} />
          <Route path="/corporate-billing" element={<ProtectedRoute><ViewCorporateBilling /></ProtectedRoute>} />
          <Route path="/corporate-questionaire" element={<ProtectedRoute><ViewCorporateQuestionaire /></ProtectedRoute>} />
          <Route path="/corporate-staff" element={<ProtectedRoute><ViewCorporateStaff /></ProtectedRoute>} />
          <Route path="/corporate-post-recovery" element={<ProtectedRoute><ViewCorporatePostRecovery /></ProtectedRoute>} />
          <Route path="/corporate-subscriptions" element={<ProtectedRoute><ViewCorporateSubscriptions /></ProtectedRoute>} />
          <Route path="/corporate-settings" element={<ProtectedRoute><ViewCorporateProfile /></ProtectedRoute>} />
          <Route path="/service-dashboard" element={<ProtectedRoute><ServiceProviderProfile /></ProtectedRoute>} />
          <Route path="/sp-clients" element={<ProtectedRoute><ViewServiceProviderClients /></ProtectedRoute>} />
          <Route path="/sp-appointments" element={<ProtectedRoute><ViewServiceProviderAppointments /></ProtectedRoute>} />
          <Route path="/sp-sessions" element={<ProtectedRoute><ViewServiceProviderSessions /></ProtectedRoute>} />
          <Route path="/sp-assessments" element={<ProtectedRoute><ViewServiceProviderAssessments /></ProtectedRoute>} />
          <Route path="/sp-treatment" element={<ProtectedRoute><ViewServiceProviderTreatment /></ProtectedRoute>} />
          <Route path="/sp-post-recovery" element={<ProtectedRoute><ViewServiceProviderPostRecovery /></ProtectedRoute>} />
          <Route path="/sp-staff" element={<ProtectedRoute><ViewServiceProviderStaff /></ProtectedRoute>} />
          <Route path="/sp-tickets" element={<ProtectedRoute><ViewServiceProviderTickets /></ProtectedRoute>} />
          <Route path="/sp-questionnaire" element={<ProtectedRoute><ViewServiceProviderQuestionnaire /></ProtectedRoute>} />
          <Route path="/sp-settings" element={<ProtectedRoute><ViewServiceProviderSettings /></ProtectedRoute>} />
          <Route path="/sp-calendar" element={<ProtectedRoute><ViewServiceProviderCalendar /></ProtectedRoute>} />
          <Route path="/sp-billing" element={<ProtectedRoute><ViewServiceProviderBills /></ProtectedRoute>} />
          <Route path="/sp-subscriptions" element={<ProtectedRoute><ViewServiceProviderSubscriptions /></ProtectedRoute>} />
          <Route path="/admin-profile" element={<ProtectedRoute><AdminProfile /></ProtectedRoute>} />
          <Route path="/appointments" element={<ProtectedRoute><ViewSuperuserAppointments /></ProtectedRoute>} />
          <Route path="/sessions" element={<ProtectedRoute><ViewSuperuserSessions /></ProtectedRoute>} />
          <Route path="/treatment" element={<ProtectedRoute><ViewSuperuserTherapyTreatment /></ProtectedRoute>} />
          <Route path="/post-recovery" element={<ProtectedRoute><ViewSuperuserPostRecovery /></ProtectedRoute>} />
          <Route path="/assessments" element={<ProtectedRoute><ViewSuperuserTherapyAssessments /></ProtectedRoute>} />
          <Route path="/user-accounts" element={<ProtectedRoute><ViewSuperuserUserAccounts /></ProtectedRoute>} />
          <Route path="/tickets" element={<ProtectedRoute><ViewSuperuserTickets /></ProtectedRoute>} />
          <Route path="/questionnaire" element={<ProtectedRoute><ViewSuperuserQuestionnaires /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><ViewSuperuserSettings /></ProtectedRoute>} />
          <Route path="/resource-centers" element={<ProtectedRoute><ViewSuperuserResourceCenters /></ProtectedRoute>} />
          <Route path="/blogposts" element={<ProtectedRoute><ViewSuperuserBlogPosts /></ProtectedRoute>} />
          <Route path="/calendars" element={<ProtectedRoute><ViewSuperuserCalendars /></ProtectedRoute>} />
          <Route path="/subscriptions" element={<ProtectedRoute><ViewSuperuserSubscriptions /></ProtectedRoute>} />
          <Route path="/billing" element={<ProtectedRoute><ViewSuperuserBilling /></ProtectedRoute>} />
          <Route path="/clients" element={<ProtectedRoute><ViewSuperuserClients /></ProtectedRoute>} />
          <Route path="/maps" element={<ProtectedRoute><ViewSuperuserMap /></ProtectedRoute>} />
          <Route path="/ecom" element={<ProtectedRoute><ViewSuperuserShopItems /></ProtectedRoute>} />
          <Route path="/blog" element={<Community />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/mental-health" element={<MentalHealth />} />
          <Route path="/service-providers" element={<ServiceProvider />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/resource-center" element={<EmergencySupport />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/corporates" element={<Corporates />} />
          <Route path="/view-billing" element={<ProtectedRoute><ViewBilling /></ProtectedRoute>} />
          <Route path="/view-questionaire" element={<ProtectedRoute><ViewQuestionaire /></ProtectedRoute>} />
          <Route path="/contacts" element={<ContactUs />} />
          <Route path="/set-password" element={<SetPassword />} />
          <Route path="/set-superuser-password" element={<SetSuperuserPassword />} />
          <Route path="/set-sp-password" element={<SetSPPassword />} />
          <Route path="/set-corporate-password" element={<SetcorporatePassword />} />
          <Route path="/set-staff-password" element={<SetStaffPassword />} />
          <Route path="/self-assessment" element={<SelfAssessmentRoute />} />
          <Route path="/payment-confirmation/:invoice_id" element={<PaymentConfirmation />} />
        <Route path="/admin_login" element={<AdminLogin />} />
        {/* Redirect all other paths to Home */}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      </ThemeProvider>
      {/* Include the Footer here if it's common to all pages */}
    </Router>
    
  );
};

export default AppRoutes;
