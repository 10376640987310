import { useEffect, useState, useContext } from "react";
import { 
  CircularProgress, Box, Typography, Grid, Card, CardContent, CardActions, 
  IconButton, Button, Avatar, Menu, MenuItem, Divider, Badge, ImageList, ImageListItem,CardMedia, TextField, Snackbar, Alert, Modal, Stepper, Step, StepLabel , Chip, Tooltip
} from "@mui/material";
import { ShoppingCart, Notifications, Settings, Logout, FitnessCenter, Visibility, Add, ChevronLeft, ChevronRight, Share, AccessTime as AccessTimeIcon, Videocam as VideocamIcon, CalendarMonth as CalendarMonthIcon, CreditCard as CreditCardIcon, PictureAsPdf } from "@mui/icons-material";
import axios from "axios";
import { LocalizationProvider, StaticDatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import { DateTime } from "luxon"; 

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { UserContext } from "../UserContext"; // Import UserContext
import "./UserDashboardPage.css"; // Import the CSS file
import UserInbox from "./UserInbox";



export default function UserDashboardPage() {
  const { user, logout } = useContext(UserContext); // Access user and logout

  const [featuredPrograms, setFeaturedPrograms] = useState([]);
  const [upcomingActivities, setUpcomingActivities] = useState([]);
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [loadingFeatured, setLoadingFeatured] = useState(false);
  const [loadingSessions, setLoadingSessions] = useState(false);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // For dropdown menu
  const open = Boolean(anchorEl);
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalBilling, setTotalBilling] = useState(0);
  const [previousMonthSessions, setPreviousMonthSessions] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [appointmentDate, setAppointmentDate] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showProgramDetails, setShowProgramDetails] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);


  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  const steps = ["Pick a Date", "Pick a Time", "Thank You!"];

  // Handle Dropdown Menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Fetch Featured Programs
  useEffect(() => {
    const fetchFeaturedPrograms = async () => {
      setLoadingFeatured(true);
      try {
        const response = await axios.get("https://api.cope.ke/subscriptions");
        const filteredPrograms = response.data.filter(
          (program) =>
            program.category === "Individual"
        );
        setFeaturedPrograms(filteredPrograms);
      } catch (error) {
        console.error("Error fetching featured programs:", error);
      } finally {
        setLoadingFeatured(false);
      }
    };
    fetchFeaturedPrograms();
  }, []);

  // Fetch Upcoming Sessions
  useEffect(() => {
    const fetchUpcomingSessions = async () => {
      setLoadingSessions(true);
      try {
        const response = await axios.get(
          `https://api.cope.ke/api/therapy/${user.user_id}`
        );
        setUpcomingSessions(response.data);

        // Count sessions from the previous month
        const currentMonth = new Date().getMonth( );
        const previousMonthCount = response.data.filter((session) => {
          const sessionDate = new Date(session.session_date);
          return sessionDate.getMonth() === currentMonth - 1;
        }).length;
        setPreviousMonthSessions(previousMonthCount);
      } catch (error) {
        console.error("Error fetching upcoming sessions:", error);
      } finally {
        setLoadingSessions(false);
      }
    };
    fetchUpcomingSessions();
  }, [user.user_id]);

  const defaultTime = new Date();
  defaultTime.setHours(8, 0, 0, 0); // Set 08:00 AM
  
  const [appointmentTime, setAppointmentTime] = useState(defaultTime); 
  

  useEffect(() => {
    const fetchRecentTransactions = async () => {
      setLoadingTransactions(true);
      try {
        const response = await axios.get(
          `https://api.cope.ke/user-billing/${user.user_id}`
        );
  
        // 1) Build a dictionary to accumulate totals by plan type
        const planTotals = {};
  
        response.data.forEach((item) => {
          let parsedPlans = [];
          try {
            parsedPlans = item.plans ? JSON.parse(item.plans) : [];
          } catch (error) {
            console.error("Error parsing transaction plans:", error);
          }
  
          // 2) Accumulate each plan's price into planTotals
          parsedPlans.forEach((plan) => {
            if (!planTotals[plan.plan_type]) {
              planTotals[plan.plan_type] = 0;
            }
            planTotals[plan.plan_type] += plan.price;
          });
        });
  
        // 3) Convert planTotals object into an array for easy mapping
        // e.g. [{ plan_type: "Basic Therapy Plan", totalPrice: 30 }, ... ]
        const groupedPlans = Object.entries(planTotals).map(([planType, sum]) => ({
          plan_type: planType,
          totalPrice: sum,
        }));
  
        // 4) Store the grouped array in state
        setRecentTransactions(groupedPlans);
  
        // 5) Compute overall total billing
        const total = Object.values(planTotals).reduce((acc, price) => acc + price, 0);
        setTotalBilling(total);
  
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Error fetching recent transactions.",
          severity: "error",
        });
      } finally {
        setLoadingTransactions(false);
      }
    };
  
    fetchRecentTransactions();
  }, [user.user_id]);

   // -----------------------------
  // FETCH UPCOMING ACTIVITIES
  // (Combining sessions, assessments, and pending billings)
  // -----------------------------
  useEffect(() => {
    const fetchUpcomingActivities = async () => {
      setLoadingSessions(true);
      try {
        // Fetch therapy sessions
        const sessionsResponse = await axios.get(`https://api.cope.ke/api/therapy/${user.user_id}`);
        // (For upcoming activities, we include sessions regardless of status)
        const sessions = sessionsResponse.data; 
        
        // Fetch patient assessments where status is 0
        const assessmentsResponse = await axios.get(`https://api.cope.ke/patient-responses/${user.user_id}`);
        const assessments = assessmentsResponse.data.filter(item => item.status === 0);
        
        // Fetch billing records where status is "Pending"
        const billingResponse = await axios.get(`https://api.cope.ke/user-billing/${user.user_id}`);
        const pendingBillings = billingResponse.data.filter(item => item.status === "Pending");
        
        // Tag each item with a type
        const sessionsMapped = sessions.map(item => ({ ...item, type: "session" }));
        const assessmentsMapped = assessments.map(item => ({ ...item, type: "assessment" }));
        const billingsMapped = pendingBillings.map(item => ({ ...item, type: "billing" }));
        
        // Combine all upcoming activities
        const combined = [...sessionsMapped, ...assessmentsMapped, ...billingsMapped];
        
        // Helper to get the activity's date (each type may have a different date field)
        const getActivityDate = (item) => {
          if(item.type === "session") return new Date(item.session_date || item.created_at);
          if(item.type === "assessment") return new Date(item.response_date);
          if(item.type === "billing") return new Date(item.billing_date);
          return new Date();
        };
        combined.sort((a, b) => getActivityDate(a) - getActivityDate(b));
        
        setUpcomingActivities(combined);
      } catch (error) {
        console.error("Error fetching upcoming activities:", error);
      } finally {
        setLoadingSessions(false);
      }
    };
    fetchUpcomingActivities();
  }, [user.user_id]);

  // -----------------------------
  // FETCH RECENT TRANSACTIONS
  // (Combining paid billings, completed assessments, and sessions in therapy)
  // -----------------------------
  useEffect(() => {
    const fetchRecentTransactions = async () => {
      setLoadingTransactions(true);
      try {
        // Fetch billings where status is "Paid"
        const billingResponse = await axios.get(`https://api.cope.ke/user-billing/${user.user_id}`);
        const paidBillings = billingResponse.data
          .filter(b => b.status === "Paid")
          .map(b => ({ ...b, type: "billing" }));
        
        // Fetch assessments where status is 1
        const assessmentResponse = await axios.get(`https://api.cope.ke/patient-responses/${user.user_id}`);
        const completedAssessments = assessmentResponse.data
          .filter(a => a.status === 1)
          .map(a => ({ ...a, type: "assessment" }));
        
        // Fetch sessions where status is "In Therapy" or "Completed Therapy"
        const sessionsResponse = await axios.get(`https://api.cope.ke/api/therapy/${user.user_id}`);
        const recentSessions = sessionsResponse.data
          .filter(s => s.status === "In Therapy" || s.status === "Completed Therapy")
          .map(s => ({ ...s, type: "session" }));
        
        // Combine all recent items
        const combinedRecent = [...paidBillings, ...completedAssessments, ...recentSessions];
        // Helper to get the transaction's date
        const getRecentDate = (item) => {
          if(item.type === "billing") return new Date(item.billing_date);
          if(item.type === "assessment") return new Date(item.response_date);
          if(item.type === "session") return new Date(item.session_date || item.created_at);
          return new Date();
        };
        combinedRecent.sort((a, b) => getRecentDate(b) - getRecentDate(a)); // Descending order
        
        setRecentTransactions(combinedRecent);
        
        // For overall billing total, sum only from the paid billings.
        const planTotals = {};
        paidBillings.forEach(item => {
          let parsedPlans = [];
          try {
            parsedPlans = item.plans ? JSON.parse(item.plans) : [];
          } catch (error) {
            console.error("Error parsing billing plans:", error);
          }
          parsedPlans.forEach(plan => {
            if (!planTotals[plan.plan_type]) planTotals[plan.plan_type] = 0;
            planTotals[plan.plan_type] += plan.price;
          });
        });
        const total = Object.values(planTotals).reduce((acc, price) => acc + price, 0);
        
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Error fetching recent transactions.",
          severity: "error",
        });
      } finally {
        setLoadingTransactions(false);
      }
    };
    fetchRecentTransactions();
  }, [user.user_id]);

  useEffect(() => {
    if (snackbar.open && snackbar.severity === "success") {
      setTimeout(() => {
        setOpenModal(false);
        setActiveStep(0);
      }, 2000);
    }
  }, [snackbar]);
  

useEffect(() => {
  if (snackbar.open && snackbar.severity === "success") {
    setTimeout(() => {
      setOpenModal(false);
      setActiveStep(0); // Reset the stepper
    }, 2000); // Delay to allow user to read the success message
  }
}, [snackbar]);


  // Handle Live Search
   // Handle Search
   const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filtered = featuredPrograms.filter((program) =>
      program.plans.some(
        (plan) =>
          plan.plan_type.toLowerCase().includes(searchTerm) ||
          plan.price.toString().includes(searchTerm)
      )
    );
    setFilteredPrograms(filtered);
  };  

  // Carousel Settings
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false, // Prevents cards from showing partially
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 600, // For mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: "red",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <ul style={{ margin: "0px" }}>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          backgroundColor: "teal",
          borderRadius: "50%",
          margin: "5px auto",
        }}
      />
    ),
  };

  // Open the modal
  const handleOpen = () => {
    setOpenModal(true);
  };

  // Close the modal
  const handleClose = () => {
    setOpenModal(false);
    setActiveStep(0); // Reset to the first step
  };

  // Handle step navigation
  const handleNext = () => {
    if (activeStep === 0 && !appointmentDate) {
      setSnackbar({
        open: true,
        message: "Please select a date before proceeding.",
        severity: "error",
      });
      return;
    }
  
    if (activeStep === 1 && !appointmentTime) {
      setAppointmentTime(new Date(new Date().setHours(8, 0, 0))); // Set default time to 8:00 AM
    }
  
    if (activeStep === steps.length - 2) {
      submitAppointmentRequest();
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };
  

  const handleBack = () => {
    setActiveStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleBackFeaturedPrograms = () => {
    setSelectedProgram(null);
    setShowProgramDetails(false);
  };

  const handleViewProgram = (program) => {
    setSelectedProgram(program);
    setShowProgramDetails(true);
  };

  
  // Handle form submission
  const submitAppointmentRequest = async () => {
    if (!appointmentDate || !appointmentTime) {
      setSnackbar({
        open: true,
        message: "Please select a valid date and time.",
        severity: "error",
      });
      return;
    }
  
    // Convert to Africa/Nairobi timezone
    const nairobiTz = "Africa/Nairobi";
  
    const appointmentDateNairobi = DateTime.fromJSDate(new Date(appointmentDate))
      .setZone(nairobiTz)
      .toISODate(); // Correctly formatted date
  
      const appointmentTimeNairobi = DateTime.fromJSDate(appointmentTime)
      .setZone(nairobiTz)
      .toISOTime({ suppressSeconds: true, includeOffset: false }); // e.g. '08:00'
  
    const requestData = {
      name: user.name,
      email: user.email,
      user_id: user.id,
      phone: user.phone,
      appointment_date: appointmentDateNairobi, // 'YYYY-MM-DD'
      appointment_time: appointmentTimeNairobi, // 'HH:mm'
    };
  
    setIsSubmitting(true);
    try {
      await axios.post("https://api.cope.ke/book_appointment", requestData);
      setSnackbar({
        open: true,
        message: "Appointment requested successfully!",
        severity: "success",
      });
      setActiveStep(2); // Move to the thank-you step
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to request an appointment.",
        severity: "error",
      });
      console.error("Error booking appointment:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  function formatDueDateTime(dateString) {
    if (!dateString) return "TBD";
    
    const dateObj = new Date(dateString);
  
    // Day suffix logic
    const day = dateObj.getDate();
    const nth = (d) => {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    };
  
    const daySuffix = nth(day);
    const dayOfWeek = dateObj.toLocaleString("en-US", { weekday: "short" }); // "Sat"
    const monthName = dateObj.toLocaleString("en-US", { month: "short" });   // "Feb"
    const year = dateObj.getFullYear();
  
    // Format time in 12-hour format
    const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true };
    const formattedTime = dateObj.toLocaleString("en-US", timeOptions); // e.g. "7:30 PM"
  
    // If you want to keep "GMT" at the end, you can just append it or detect the offset
    return `${dayOfWeek}, ${monthName} ${day}${daySuffix} ${year} at ${formattedTime} GMT`;
  }

// Define a helper function to format date/time
function formatSessionDateTime(dateString, timeString) {
  // If either is missing, return "TBD"
  if (!dateString || !timeString) return "TBD";

  // Convert both to Date objects
  const dateObj = new Date(dateString);
  // For time, if your API returns "17:00:00", parse that into a Date:
  const [hours, minutes] = timeString.split(":");
  dateObj.setHours(hours, minutes || 0);

  // Build the suffix for day
  const day = dateObj.getDate();
  const nth = (d) => {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  };

  // Format pieces
  const dayOfWeek = dateObj.toLocaleString("en-US", { weekday: "short" }); // "Mon"
  const monthName = dateObj.toLocaleString("en-US", { month: "short" });   // "Feb"
  const year = dateObj.getFullYear();
  const dayWithSuffix = `${day}${nth(day)}`; // e.g. "24th"

  // Format the time portion to "5:00 PM" (12-hour clock)
  const timeOptions = { hour: "numeric", minute: "2-digit", hour12: true };
  const formattedTime = dateObj.toLocaleString("en-US", timeOptions);

  return `${dayOfWeek}, ${monthName} ${dayWithSuffix} ${year} at ${formattedTime}`;
}

  const handleShare = (program) => {
    const shareData = {
      title: program.plan,
      text: `Check out this amazing program: ${program.plan}`,
      url: window.location.href, // You can modify the URL to a specific page
    };

    if (navigator.share) {
      navigator.share(shareData).catch((error) => console.error('Error sharing program:', error));
    } else {
      alert('Sharing is not supported on your browser.');
    }
  };


  return (
    <Box sx={{ minHeight: "100vh", backgroundColor: "#f9f9f9" }}>
      {/* Header */}
      

      {/* Main Content */}
      <Box sx={{ padding: "20px" }}>
        {/* Welcome Section */}
        <Box sx={{ marginBottom: 4 }}>
          <Typography color="#666">Explore our wellness programs and services</Typography>
          <Divider sx={{ marginTop:2 }}/>
        </Box>
        
        {/* Dashboard Cards */}
        <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography color="#00695C" variant="h5">Book Appointment</Typography>
                <Box display="flex" justifyContent="left" alignItems="center">
                <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={handleOpen}
            sx={{
              textTransform: "none",
              borderColor: "#006B54",
              "&:hover": { borderColor: "#004d40"},
            }}
          >
            Book
          </Button>
          
                </Box>
                <Typography color="#666" variant="body2" >
                  Book appointment with licensed practioners
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography color="#00695C" variant="h5">Therapy Sessions</Typography>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {upcomingSessions.length} Active Programs
                </Typography>
                <Typography  variant="body2" color="#666">
                  {previousMonthSessions} from last month
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography color="#00695C" variant="h5">Financial Overview</Typography>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Ksh {totalBilling.toFixed(2)}
                </Typography>
                <Typography variant="body2" color="#666">
                  Current balance
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Divider sx={{ marginTop:2, marginBottom: 3}}/>
                
        {/* Featured Programs */}
        <Box sx={{ marginTop: 4 }}>
      {/* Header with Search */}
      <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} alignItems="center" gap={2}>
        <Typography
          color="#00695C"
          variant="h4"
          sx={{ fontSize: { xs: "18px", sm: "24px" }, flex: 1 }}
        >
          Featured Wellness Programs
        </Typography>
        
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search programs"
          size="small"
          sx={{ width: { xs: "100%", sm: "300px" } }}
        />
      </Box>
      <Divider sx={{ marginTop:2, marginBottom: 3}}/>

      <Box>
        {showProgramDetails && selectedProgram ? (
          // Details view of the selected program
          <Box>
            <Box display="flex" alignItems="center" sx={{ padding: "10px" }}>
              <IconButton onClick={handleBackFeaturedPrograms}>
                <ChevronLeft />
              </IconButton>
              <Typography variant="h6" color="primary">
                {selectedProgram.plan}
              </Typography>
            </Box>
            <Box sx={{ padding: "20px" }}>
              <Typography variant="h6" color="#006B54" gutterBottom>
                {selectedProgram.plan}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: "16px" }}>
                {selectedProgram.description}
              </Typography>
              <CardMedia
                component="img"
                height="200"
                image={selectedProgram.image || "/placeholder.svg"}
                alt={selectedProgram.plan}
                sx={{ borderRadius: "8px" }}
              />
            </Box>
          </Box>
        ) : (
          // Render carousel if no program is selected
          <Box>
        {/* Carousel */}
      {loadingFeatured ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <div className="slider-container" sx={{ overflow: "hidden", padding: "20px", }}>
        <Slider  {...sliderSettings}>
          {featuredPrograms.map((program, index) => (
            
            <Box key={index} sx={{ padding: "5px" }}>

<Card
              key={program.id}
              sx={{
                width: '80%',
                margin: "0 15px",
                boxShadow: 3,
                "&:hover": { boxShadow: 6, transform: "scale(1.03)" },
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
              }}
            >
              <Box sx={{ position: "relative" }}>
                        <CardMedia
                          component="img"
                          height="140"
                          image={program.image || "/placeholder.svg"}
                          alt={program.plan}
                          sx={{ borderRadius: "4px 4px 0 0" }}
                        />
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: "8px",
                            right: "8px",
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
                          }}
                          onClick={() => handleShare(program)}
                        >
                          <Share />
                        </IconButton>
                      </Box>
              <CardContent>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography textAlign='center' variant="h6" sx={{ color: "#006B54", fontWeight: "bold" }}>
                    {program.plan}
                  </Typography>
                </Box>
                <Typography textAlign='center' variant="body2" color="#666">
                  {program.description}
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center">
                <Button
            variant="outlined"
            startIcon={<Visibility />}
            onClick={() => handleViewProgram(program)}
            sx={{
              textTransform: "none",
              borderColor: "#006B54",
              "&:hover": { borderColor: "#004d40"},
            }}
          >
            View
          </Button>
          
                </Box>
                
              </CardContent>
            </Card>
            </Box>
          ))}
        </Slider>
  </div>
      )}
      </Box>
        )}
        </Box>
    </Box>
    <Divider sx={{ marginTop:2, marginBottom: 2}}/>
{/* Upcoming Activities and Recent Transactions */}
<Grid container spacing={3} sx={{ marginTop: 4 }}>
          {/* UPCOMING ACTIVITIES */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <Typography variant="h6" sx={{ color: "#00695C" }}>
                    Upcoming Activities
                  </Typography>
                  <CalendarMonthIcon sx={{ color: "#F9A64F" }} />
                </Box>
                {loadingSessions ? (
                  <Box display="flex" justifyContent="center" alignItems="center" py={2}>
                    <CircularProgress />
                  </Box>
                ) : upcomingActivities.length === 0 ? (
                  <Typography>No upcoming activities...</Typography>
                ) : (
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    {
  upcomingActivities.map((activity, index) => {
    if (activity.type === "session") {
      // 2) Format the session date/time
      const dateTimeString = formatSessionDateTime(
        activity.appointment_date,
        activity.appointment_time
      );

      // 3) Logic to show "Join Now" only if the session is paid
      // Adjust as needed if your field is named differently
      const isPaid = activity.bill_status === "Paid";

      return (
        <Box
          key={activity.session_id || index}
          sx={{
            border: "1px solid",
            borderColor: "divider",
            borderRadius: 2,
            p: 2,
            backgroundColor: index === 0 ? "background.paper" : "action.hover",
          }}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            {/* Time + "Online" Chips */}
            <Box sx={{ display: "flex", gap: 1 }}>
              <Chip
                size="small"
                icon={<AccessTimeIcon fontSize="small" />}
                label={activity.appointment_time || "TBD"}
              />
              <Chip
                size="small"
                icon={<VideocamIcon fontSize="small" />}
                label="Online"
                variant="outlined"
                color="primary"
              />
            </Box>

            {/* Session Info */}
            <Typography variant="body1" sx={{ color: "#666", fontWeight: 500 }}>
              Don&apos;t miss our{" "}
              {activity.session_type ||
                (activity.plans
                  ? JSON.parse(activity.plans)[0].plan_type
                  : "Session")}{" "}
              session hosted by {activity.provider_name || "Cope"} on{" "}
              {dateTimeString} — this is an opportunity you won&apos;t want to
              miss!
            </Typography>
          </Box>

          {/* 4) "Join Now" only if the session is paid; otherwise no button */}
          {isPaid && (
            <Box sx={{ mt: 1 }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#2d6a4f",
                  "&:hover": { backgroundColor: "#1b4332" },
                }}
              >
                Join Now
              </Button>
            </Box>
          )}
        </Box>
      );
    } 
    else if (activity.type === "assessment") {
      return (
        <Box
          key={activity.response_id || index}
          sx={{ border: "1px solid", borderColor: "divider", borderRadius: 2, p: 2 }}
        >
          <Typography variant="body1" sx={{ color: "#666", fontWeight: 500 }}>
            Upcoming Assessment from {activity.name} scheduled on {activity.response_date || "TBD"}.
          </Typography>
          <Button variant="outlined">View Assessment</Button>
        </Box>
      );
    } 
    else if (activity.type === "billing") {
      const formattedDueDate = formatDueDateTime(activity.due_date);
      return (
        <Box
      key={activity.billing_id || index}
      sx={{ border: "1px solid", borderColor: "divider", borderRadius: 2, p: 2 }}
    >
      <Typography variant="body1" sx={{ color: "#666", fontWeight: 500 }}>
        Pending Billing: <strong>Invoice # {activity.invoice_no}</strong> for{" "}
        {activity.plans ? JSON.parse(activity.plans)[0].plan_type : "Billing"}{" "}
        due on {formattedDueDate}.
      </Typography>

      {/* Replace the button with an icon + tooltip */}
      <Tooltip title="View Invoice">
        <IconButton onClick={() => {/* handleViewInvoice(activity.invoice_no) */}}>
          <PictureAsPdf sx={{ color: "#00695C" }} />
        </IconButton>
      </Tooltip>
    </Box>
      );
    } 
    else {
      return null;
    }
  })
}
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* RECENT TRANSACTIONS */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <Typography variant="h6" sx={{ color: "#00695C" }}>
                    Recent Transactions
                  </Typography>
                  <CreditCardIcon sx={{ color: "#F9A64F" }} />
                </Box>
                <Box sx={{ maxHeight: 400, overflowY: "auto", pr: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                  {loadingTransactions ? (
                    <Box display="flex" justifyContent="center" alignItems="center" py={2}>
                      <CircularProgress />
                    </Box>
                  ) : recentTransactions.length === 0 ? (
                    <Typography>No recent transactions</Typography>
                  ) : (
                    recentTransactions.map((item, index) => {
                      if (item.type === "billing") {
                        return (
                          <Box
                            key={item.billing_id || index}
                            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", border: "1px solid", borderColor: "divider", borderRadius: 2, p: 2 }}
                          >
                            <Box>
                              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                Invoice {item.invoice_no}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                Date: {item.billing_date || "TBD"}
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                              <Chip label={`Ksh ${item.amount}`} variant="outlined" />
                              <Chip label={item.status} color="secondary" />
                            </Box>
                          </Box>
                        );
                      } else if (item.type === "assessment") {
                        return (
                          <Box
                            key={item.response_id || index}
                            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", border: "1px solid", borderColor: "divider", borderRadius: 2, p: 2 }}
                          >
                            <Box>
                              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                Completed Assessment
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                Date: {item.response_date || "TBD"}
                              </Typography>
                            </Box>
                            <Chip label="Completed" color="primary" />
                          </Box>
                        );
                      } else if (item.type === "session") {
                        return (
                          <Box
                            key={item.session_id || index}
                            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", border: "1px solid", borderColor: "divider", borderRadius: 2, p: 2 }}
                          >
                            <Box>
                              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                Therapy Session: {item.session_type || (item.plans ? JSON.parse(item.plans)[0].plan_type : "Session")}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                Date: {item.session_date || item.created_at || "TBD"}
                              </Typography>
                            </Box>
                            <Chip label={item.status} color="secondary" />
                          </Box>
                        );
                      } else {
                        return null;
                      }
                    })
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

    
      </Box>
      {/* Modal */}
      <Modal open={openModal} onClose={handleClose}>
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
    }}
  >
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label, index) => (
        <Step key={index}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>

    {activeStep === 0 && (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={appointmentDate}
          onChange={(newValue) => setAppointmentDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
          disablePast
        />
      </LocalizationProvider>
    )}

    {activeStep === 1 && (
      // Time Picker Component
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          label="Pick a Time"
          value={appointmentTime}
          onChange={(newValue) => setAppointmentTime(newValue)}
          renderInput={(params) => <TextField fullWidth {...params} />}
          ampm
        />
      </LocalizationProvider>
    
    )}

    {activeStep === 2 && (
      <Typography variant="h6" textAlign="center" color="success.main">
        Thank you for booking your appointment!
      </Typography>
    )}




    <Box display="flex" justifyContent="space-between" mt={2}>
      {activeStep > 0 && (
        <Button
          startIcon={<ChevronLeft />}
          onClick={handleBack}
        >
          Back
        </Button>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          if (activeStep === steps.length - 2) {
            submitAppointmentRequest();
          } else {
            handleNext();
          }
        }}
        disabled={
          (activeStep === 0 && !appointmentDate) || (activeStep === 1 && !appointmentTime)
        }
      >
        {activeStep === steps.length - 2 ? "Submit" : "Next"}
      </Button>
    </Box>
  </Box>
</Modal>

              {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>

    </Box>

    
  );
  
}


