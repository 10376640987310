import React, { useState, useEffect } from 'react'; 
import {
  Box,
  Typography,
  Button,
  Container,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Chip,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // For redirection after success

import logo from "../assets/logo.png";

const PatientQuestionnaire = ({ provider_id, appointment_id, user }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate(); // Hook for navigation

  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({ name: '', phone: '', email: '' });
  const [questions, setQuestions] = useState([]);
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(false); // Track success/error for Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [appointmentId, setAppointmentId] = useState(null);

  useEffect(() => {
    setLoadingQuestions(true);
  
    // Ensure provider_id is a valid number
    const isProviderIdValid = typeof provider_id === 'number' && !isNaN(provider_id);
  
    // Determine the endpoint URL based on the validated provider_id
    const questionsEndpoint = isProviderIdValid && provider_id === 7
      ? 'https://api.cope.ke/questions'
      : isProviderIdValid
      ? `https://api.cope.ke/sp-questionnaire/${provider_id}`
      : 'https://api.cope.ke/questions'; // Optional: Use a default fallback if provider_id is invalid
  
    axios.get(questionsEndpoint)
      .then((response) => {
        setQuestions(response.data);
        setLoadingQuestions(false);
      })
      .catch((error) => {
        console.error("Error fetching questions:", error);  // Log the exact error for debugging
        setSnackbarMessage('Error fetching questions');
        setError(true);
        setLoadingQuestions(false);
        setSnackbarOpen(true);
      });
  
    if (user) {
      setFormValues({
        name: user.name || '',
        phone: user.phone || '',
        email: user.email || '',
        userId: user.user_id || '',
      });
    }
  }, [provider_id, user]);

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    if (!input.startsWith('254')) {
      setFormValues({ ...formValues, phone: '254' });
    } else if (input.length <= 12 && /^[0-9]*$/.test(input)) {
      setFormValues({ ...formValues, phone: input });
    }
  };

  const handleChipClick = (question_id, question_text, option) => {
    setFormValues({
      ...formValues,
      [`question_${question_id}`]: { answer: option, question_text: question_text },
    });
    if (activeStep === questions.length - 1) {
      setActiveStep(questions.length);
    } else {
      handleNext();
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!validateEmail(formValues.email)) {
      setSnackbarMessage('Invalid email format');
      setError(true);
      setSnackbarOpen(true);
      return;
    }

    setLoadingSubmit(true);
    try {
      const responses = Object.keys(formValues)
        .filter(key => key.startsWith("question_"))
        .map(key => {
          const questionId = key.split('_')[1];
          return {
            question_id: parseInt(questionId),
            question_text: formValues[key].question_text,
            answer: formValues[key].answer,
          };
        });

      // Conditionally set the submit endpoint based on provider_id
      const submitEndpoint = provider_id === 7 
        ? 'https://api.cope.ke/questions' 
        : 'https://api.cope.ke/sp-responses';

      await axios.post(submitEndpoint, {
        name: formValues.name,
        email: formValues.email,
        phone: formValues.phone,
        provider_id: provider_id,
        user_id: formValues.userId,
        appointment_id: appointment_id,
        responses,
      });

      setSnackbarMessage('Submission Successful!');
      setError(false); // No error, success
      setSnackbarOpen(true);

      // Redirect to the homepage after a short delay
      setTimeout(() => {
        window.open('https://cope.ke/', '_blank');
      }, 2000); // 2-second delay before redirecting

    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message;
      setSnackbarMessage('Error submitting data: ' + errorMessage);
      setError(true); // Error occurred
      setSnackbarOpen(true);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const renderQuestions = () => {
    // 1) If still loading, show spinner
    if (loadingQuestions) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      );
    }
  
    // 2) If no questions were returned
    if (questions.length === 0) {
      return <Typography>No questions available.</Typography>;
    }
  
    // 3) Otherwise, render the current question & chips
    const currentQuestion = questions[activeStep];
  
    return (
      <Grid container spacing={isSmallScreen ? 1 : 2}>
        <Grid item xs={12}>
          <Typography
            variant={isSmallScreen ? "body2" : "body1"}
            gutterBottom
            sx={{ color: theme.palette.primary.main }}
          >
            {currentQuestion.question_text}
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {currentQuestion.options.split(",").map((option, idx) => {
              const trimmedOption = option.trim();
              const selected =
                formValues[`question_${currentQuestion.question_id}`]?.answer === trimmedOption;
  
              return (
                <Chip
                  key={idx}
                  label={trimmedOption}
                  clickable
                  sx={{
                    backgroundColor: selected ? "#00695C" : "#F9A64F",
                    color: selected ? "white" : "black",
                    fontSize: isSmallScreen ? "0.8rem" : "1rem",
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: selected ? "#F9A64F" : "#00695C",
                      color: selected ? "black" : "white",
                    },
                  }}
                  onClick={() =>
                    handleChipClick(
                      currentQuestion.question_id,
                      currentQuestion.question_text,
                      trimmedOption
                    )
                  }
                />
              );
            })}
          </Box>
        </Grid>
      </Grid>
    );
  };
  

  return (
    <>
      {/* Top-Centered Logo */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: isSmallScreen ? 1 : 2 }}>
        <img
          src={logo}
          alt="Logo"
          style={{ height: isSmallScreen ? "60px" : "80px", marginRight: "10px" }}
        />
      </Box>

      {/* Welcoming Message */}
      <Typography color='#00695C' variant={isSmallScreen ? 'h5' : 'h4'} align="center" sx={{ mt: isSmallScreen ? 2 : 3 }}>
        Wellness Self-Assessment
      </Typography>
      <Typography variant={isSmallScreen ? 'body2' : 'body1'} align="center" sx={{ mb: 4 }}>
        We're here to guide you through a quick assessment to help us understand your needs better.
      </Typography>

      <Container
        maxWidth={isSmallScreen ? "xs" : "sm"}
        sx={{
          backgroundColor: '#F5F5F5',
          padding: isSmallScreen ? '10px' : '20px',
          borderRadius: '8px',
          marginTop: '20px',
          maxHeight: '85vh',
          overflowY: 'auto',
        }}
      >
        <Typography variant={isSmallScreen ? "h6" : "h6"} gutterBottom sx={{ color: theme.palette.primary.main }}>
          Self-Assessment Questionnaire
        </Typography>

        {/* Stepper */}
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: isSmallScreen ? 2 : 4 }}>
          {questions.map((_, index) => (
            <Step key={index}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>

        {/* Render current question */}
        <form>
          {activeStep < questions.length ? renderQuestions() : (
            <Box sx={{ mt: 3 }}>
              <Typography variant={isSmallScreen ? 'body2' : 'body1'}>Please provide your contact details:</Typography>
              <TextField
                required
                id="name"
                label="Name"
                name="name"
                value={formValues.name}
                onChange={handleChange}
                fullWidth
                sx={{ mb: 2 }}
                InputLabelProps={{ style: { color: '#2C1C18' } }}
              />
              <TextField
                required
                id="phone"
                label="Phone"
                name="phone"
                value={formValues.phone}
                onChange={handlePhoneChange}
                fullWidth
                sx={{ mb: 2 }}
                inputProps={{ maxLength: 12 }}
                InputLabelProps={{ style: { color: '#2C1C18' } }}
              />
              <TextField
                required
                id="email"
                label="Email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                fullWidth
                sx={{ mb: 2 }}
                InputLabelProps={{ style: { color: '#2C1C18' } }}
              />

              {/* Submit Button */}
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={loadingSubmit}
                  sx={{ backgroundColor: '#00695C' }}
                >
                  Submit
                </Button>
                {loadingSubmit && <CircularProgress size={24} sx={{ position: 'absolute' }} />}
              </Box>
            </Box>
          )}
        </form>
      </Container>

      {/* Snackbar */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={error ? 'error' : 'success'}
          sx={{ bgcolor: error ? 'red' : 'green', color: '#fff' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PatientQuestionnaire;
