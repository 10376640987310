import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        // 1) Fetch the main user object
        const userResponse = await axios.get("https://api.cope.ke/users");
        const userData = userResponse.data;

        // If userData is valid and has a user_id, fetch appointment info
        if (userData && userData.user_id) {
          try {
            // 2) Fetch the appointment for this user
            const appointmentResponse = await axios.get(
              `https://api.cope.ke/appointments/${userData.user_id}`
            );
            const appointmentData = appointmentResponse.data;
            
            // 3) Merge appointment data (e.g., appointment_id) into user
            // You can store just the appointment_id or the whole object
            userData.appointment_id = appointmentData.appointment_id;
            // Or userData.appointment = appointmentData;
          } catch (err) {
            console.error("Failed to fetch appointment data", err);
          }
        }

        // 4) Save final user object (including appointment info) in context
        setUser(userData);
      } catch (error) {
        console.error("Failed to fetch user data", error);
      }
    };

    fetchUser();
  }, []);

  const login = (userData) => {
    setUser(userData);
    localStorage.setItem("user", JSON.stringify(userData));
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
    axios
      .post("https://api.cope.ke/logout")
      .catch((error) => console.error("Failed to logout", error));
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};
